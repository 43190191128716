import { Component, effect, input, OnInit, output, ViewChild } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { OptionCardModule } from '@onep/ng-uikit-lib/ui/option-card';
import { BillingOrder, BillingPaymentMethod, PaymentMethodType } from '../../../../generated/types';
import { HashMap, TranslocoService } from '@jsverse/transloco';
import { camelCase } from '../../../utils/string-utils';
import { TranslocoLocaleService } from '@jsverse/transloco-locale';
import { SpinnerModule } from '@onep/ng-uikit-lib/ui/spinner';
import { StripeCreditCardComponent } from '../stripe-credit-card/stripe-credit-card.component';
import { FormFieldsModule } from '@onep/ng-uikit-lib/form-fields';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { GladyTrackingLogLabelizedEvent, TrackingService } from '@onep/front-common-lib/tracking';

@Component({
  selector: 'app-payment-method-selector',
  standalone: true,
  imports: [
    SharedModule,
    OptionCardModule,
    SpinnerModule,
    StripeCreditCardComponent,
    ReactiveFormsModule,
    FormFieldsModule,
  ],
  templateUrl: './payment-method-selector.component.html',
  styleUrl: './payment-method-selector.component.scss',
})
export class PaymentMethodSelectorComponent implements OnInit {
  @ViewChild(StripeCreditCardComponent) stripeCreditCardComponent?: StripeCreditCardComponent;

  token = input.required<string>();
  order = input<BillingOrder>();

  paymentMethodSelectedChange = output<PaymentMethodType | undefined>();

  loaded: boolean = false;
  selectedPaymentMethodType?: PaymentMethodType;

  readonly cardPaymentMethod = PaymentMethodType.CreditCard;

  private readonly titleKey = 'title';
  private readonly descriptionKey = 'description';
  private readonly extraDescriptionKey = 'extraDescription';

  formGroup!: FormGroup;

  constructor(
    private readonly translocoService: TranslocoService,
    private readonly translocoLocaleService: TranslocoLocaleService,
    private readonly trackingService: TrackingService,
    private readonly formBuilder: FormBuilder,
  ) {
    effect(() => {
      this.loaded = this.order() !== undefined;

      if (this.loaded && this.order()?.paymentMethods.length === 1) {
        this.onPaymentMethodClick(this.order()?.paymentMethods[0].type as PaymentMethodType);
      }
    });
  }

  ngOnInit() {
    this.formGroup = this.formBuilder.group({
      selectedPaymentMethod: [null, [Validators.required]],
    });
  }

  isEnabled(paymentMethod: BillingPaymentMethod): boolean {
    let dueAmount = this.order()?.amount.netPriceDue ?? 0;
    return this.isValidAmount(paymentMethod, this.order()?.amount.includingTaxAmount) && dueAmount !== 0;
  }

  private isValidAmount(paymentMethod: BillingPaymentMethod, includingTaxAmount?: number): boolean {
    if (includingTaxAmount && paymentMethod.maxAmount) {
      return includingTaxAmount <= paymentMethod.maxAmount;
    }
    return includingTaxAmount !== undefined;
  }

  onPaymentMethodClick(paymentMethodType: PaymentMethodType): void {
    if (this.selectedPaymentMethodType != paymentMethodType) {
      this.selectedPaymentMethodType = paymentMethodType;
    }

    if (this.selectedPaymentMethodType) {
      this.trackingService.sendEvent(
        new GladyTrackingLogLabelizedEvent('paymentMethodClicked', this.selectedPaymentMethodType.toLowerCase()),
      );
    }

    this.paymentMethodSelectedChange.emit(this.selectedPaymentMethodType);
  }

  getTitle(paymentMethod: BillingPaymentMethod): string {
    return this.getPaymentMethodTranslation(paymentMethod, this.titleKey);
  }

  getDescription(paymentMethod: BillingPaymentMethod): string {
    if (paymentMethod.maxAmount) {
      const keyParameters = {
        maxAmount: this.translocoLocaleService.localizeNumber(paymentMethod.maxAmount, 'currency'),
      };
      return this.getPaymentMethodTranslation(paymentMethod, this.descriptionKey, keyParameters);
    }
    return this.getPaymentMethodTranslation(paymentMethod, this.descriptionKey);
  }

  getExtraDescription(paymentMethod: BillingPaymentMethod): string {
    return this.getPaymentMethodTranslation(paymentMethod, this.extraDescriptionKey);
  }

  private getPaymentMethodTranslation(
    paymentMethod: BillingPaymentMethod,
    key: string,
    keyParameters?: HashMap,
  ): string {
    return this.translocoService.translate(`payment.method.${camelCase(paymentMethod.type)}.${key}`, keyParameters);
  }

  onPaymentClick(successUrl: string): void {
    if (this.selectedPaymentMethodType === PaymentMethodType.CreditCard) {
      this.stripeCreditCardComponent?.onPaymentClick(successUrl);
    }
  }

  refreshSelectedPaymentMethod() {
    if (this.selectedPaymentMethodType === PaymentMethodType.CreditCard) {
      this.stripeCreditCardComponent?.createPaymentIntent();
    }
  }
}
