<ng-container *transloco="let t; read: 'payment'">
  @if (order) {
    <app-header-navbar
      [journeyUrl]="order.journeyUrl"
      [journeyName]="order.journeyName"
    ></app-header-navbar>
  }
  <div class="container">
    <div class="payment-layout">
      <div class="payment-layout-content">
        <h1>{{ t('title') }}</h1>
        @if (shouldShowPaymentMethodSelector) {
          <div class="security">
            <img
              ngSrc="assets/icons/shield-check.svg"
              class="shield-icon"
              height="24"
              width="24"
              alt="{{ t('securityIcon') }}"
              aria-hidden="true"
            />
            <p class="security-information onep-text-body-1">{{ t('security') }}</p>
          </div>
          <app-payment-method-selector
            [token]="token"
            [order]="order"
            (paymentMethodSelectedChange)="onPaymentMethodSelectedChange($event)"
          ></app-payment-method-selector>
        }
        @if (order) {
          <app-billing-address [address]="order.billingAddress"></app-billing-address>
        }
      </div>
    </div>

    @if (order) {
      <div class="summary-layout">
        <app-payment-summary
          [token]="token"
          [order]="order"
          (promoCodeUpdated)="onPromoCodeUpdated()"
        ></app-payment-summary>
      </div>
    }
    @if (order) {
      <div class="footer">
        <app-validate-payment
          [token]="token"
          [order]="order"
          [paymentMethodType]="paymentMethodSelected!"
          [successUrl]="getSuccessUrl()"
          (paymentClick)="onPaymentClick($event)"
        ></app-validate-payment>
      </div>
    }
  </div>
</ng-container>
