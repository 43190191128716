import { Component, input, output } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ButtonModule } from '@onep/ng-uikit-lib/ui/button';
import { WindowService } from '@onep/front-common-lib/window';
import { environment } from '../../../../environments/environment';
import {
  AsyncPaymentMethod,
  BillingOrder,
  PaymentMethodType,
  ProductSkuType,
  ValidatePaymentInput,
  ValidatePaymentResponse,
} from '../../../../generated/types';
import { PaymentService } from '../../../services/payment.service';
import { ModalService, ModalType } from '@onep/ng-uikit-lib/ui/modal';
import { PaymentProcessModalComponent } from '../../modals/payment-process-modal/payment-process-modal.component';
import { BehaviorSubject, delay, of } from 'rxjs';
import { PaymentProcessStatus } from '../../../models/enums/payment-process-status';
import { OverlayService } from '@onep/ng-uikit-lib/overlay';
import { PaymentPageStatus } from '../../../models/enums/payment-page-status';

@Component({
  selector: 'app-validate-payment',
  standalone: true,
  imports: [SharedModule, ButtonModule],
  providers: [ModalService, OverlayService],
  styleUrl: './validate-payment.component.scss',
  templateUrl: './validate-payment.component.html',
})
export class ValidatePaymentComponent {
  token = input.required<string>();
  order = input.required<BillingOrder>();
  paymentMethodType = input.required<PaymentMethodType>();
  successUrl = input<string>();

  paymentClick = output<string>();

  private readonly modalStatusSubject: BehaviorSubject<PaymentProcessStatus>;

  constructor(
    private readonly windowService: WindowService,
    private readonly paymentService: PaymentService,
    private readonly modalService: ModalService,
  ) {
    this.modalStatusSubject = new BehaviorSubject<PaymentProcessStatus>(PaymentProcessStatus.PROCESSING);
  }

  onValidatePaymentClick(): void {
    const successUrl = `${this.getSuccessUrl()}?orderId=${this.order().orderId}`;
    this.paymentClick.emit(successUrl);

    if (this.paymentMethodType() !== PaymentMethodType.CreditCard) {
      const asyncPaymentMethodType: PaymentMethodType =
        this.order().amount.netPriceDue === 0 ? PaymentMethodType.None : this.paymentMethodType();

      const validatePaymentInput: ValidatePaymentInput = {
        token: this.token(),
        asyncPaymentMethod: this.paymentMethodTypeToAsyncPaymentMethod(asyncPaymentMethodType),
      };
      this.validatePayment(validatePaymentInput, successUrl);
    }
  }

  private validatePayment(validatePaymentInput: ValidatePaymentInput, successUrl: string): void {
    this.paymentService.updateCurrentPaymentStatus(PaymentPageStatus.PROCESSING);

    this.openLoadingModal();
    this.paymentService.validatePayment(validatePaymentInput).subscribe({
      next: (validatePaymentResponse: ValidatePaymentResponse) => {
        if (validatePaymentResponse.paymentValidated) {
          this.paymentService.updateCurrentPaymentStatus(PaymentPageStatus.SUCCESS);
          this.replaceLoadingModalToSuccessModal();
          of(null)
            .pipe(delay(1500))
            .subscribe(() => this.windowService.redirect(successUrl));
        } else {
          console.warn(validatePaymentResponse.failureMessage);
          this.replaceLoadingModalToErrorModal();
        }
      },
      error: (error: Error) => {
        console.error(error.message);
        this.replaceLoadingModalToErrorModal();
      },
    });
  }

  private replaceLoadingModalToSuccessModal(): void {
    this.updateModalStatus(PaymentProcessStatus.SUCCESS);
  }

  private replaceLoadingModalToErrorModal(): void {
    this.updateModalStatus(PaymentProcessStatus.ERROR);
  }

  private openLoadingModal(): void {
    this.updateModalStatus(PaymentProcessStatus.PROCESSING);
    this.modalService.show({
      type: ModalType.LoadingModal,
      component: PaymentProcessModalComponent,
      backDropClose: false,
      input: {
        status$: this.modalStatusSubject.asObservable(),
        isAsync$: true,
      },
    });
  }

  private updateModalStatus(status: PaymentProcessStatus): void {
    this.modalStatusSubject.next(status);
  }

  private paymentMethodTypeToAsyncPaymentMethod(paymentMethodType: PaymentMethodType): AsyncPaymentMethod {
    switch (paymentMethodType) {
      case PaymentMethodType.Check:
        return AsyncPaymentMethod.Check;
      case PaymentMethodType.BankTransfer:
        return AsyncPaymentMethod.BankTransfer;
      case PaymentMethodType.None:
        return AsyncPaymentMethod.None;
      default:
        throw new Error(`${paymentMethodType} has no equivalent in AsyncPaymentMethod enum`);
    }
  }

  private getSuccessUrl(): string {
    return this.successUrl() !== undefined && this ? this.successUrl()! : environment.url.payment.success;
  }

  getHomeUrl(): string {
    return environment.url.glady.home;
  }

  getLogoAssetUrl() {
    return this.order().productSkuType === ProductSkuType.GiftPaper
      ? 'assets/logos/logo_tir_groupe.svg'
      : 'assets/logos/logo-glady-full-red-pluxee-main.svg';
  }
}
